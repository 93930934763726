import React from 'react';
import { isLogin, showWarning } from '../../helpers';

const Chat = () => {
  let chatLink = localStorage.getItem('chat_link');
  let chatFirst=localStorage.getItem('chat_first');
  console.log("[chat] chatLink=",chatLink);
  console.log("[chat] chatFirst=",chatFirst);
  
  if (!chatLink) {
        let status = localStorage.getItem('status');
        console.log("[chat] status=",status);
        if (status) {
            status = JSON.parse(status);
            if (status.chat_link!="") {
            chatLink=status.chat_link;
            localStorage.setItem('chat_link', chatLink);
            }
        }
        console.log("[chat] status,chatLink=",chatLink);
  }
  console.log("[chat] chatLink=",chatLink);
  if (chatLink==''||!isLogin())
  {
      showWarning('请先登录！');
      return
    }
    if (!chatFirst)
    {
        //showWarning('聊天 首次加载较慢，请稍等。。。');
        localStorage.setItem('chat_first','0');
    }
  return (
    <iframe
      src={chatLink}
      style={{ width: '100%', height: '85vh', border: 'none' }}
    />
  );
};


export default Chat;
